
import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import { fetchData } from "./api";
import CONFIG from "./tableConfig";

class RefundApprovedItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			total: null,
      errors: {},
		};
		this._tableRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

  render() {
		return (
			<div className='bg-white rounded'>
				<table class="table">
					<thead className='text-center'>
						<tr>
							<th scope="col">Total Approved</th>
							<th scope="col">Total Refund Approved (kidus)</th>
							<th scope="col">Number of Customers</th>
						</tr>
					</thead>
					<tbody className='text-center'>
						<tr>
							<td>{this.state?.total_requests}</td>
							<td>{this.state?.total_refund_amount}</td>
							<td>{this.state?.number_or_customers}</td>
						</tr>
					</tbody>
				</table>
				<Table
					title='Refund Approved'
					key={this.state.muiTableKey}
					options={{
						toolbar: true,
            sorting: false, filtering: false,
						debounceInterval: 1000,
					}}
					actions={CONFIG.TABLE_ACTIONS(this)}
          columns={CONFIG.TABLE_COLUMNS(this)}
					tableRef={this._tableRef}
					data={(query) => fetchData(query, this)}
				/>
			</div>
		);
	}
}

export default withSnackbar(RefundApprovedItems)
