import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import { fetchData } from "./api";
import CONFIG from "./tableConfig";
import DeleteItem from './_cancel_form'

class CancellationRequestedItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			total: null,
      errors: {},
		};
		this._tableRef = React.createRef();
    this._DeleteItemRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

  render() {
		return (
			<div className='bg-white rounded'>
        <DeleteItem callBackDeleteItem={this.reloadData} ref={this._DeleteItemRef}/>
				{this.state.summary &&
					<table class="table">
						<thead className='text-center'>
							<tr>
								<th scope="col" className='text-start'>#</th>
								<th scope="col">Total Requests</th>
								<th scope="col">Total Amount (subtotal)</th>
								<th scope="col">Number of Customers</th>
							</tr>
						</thead>
						<tbody className='text-center'>
							<tr>
								<td className='text-start'>Refundable</td>
								<td>{this.state.summary.refundable_count}</td>
								<td>{this.state.summary.refundable_amount}</td>
								<td>{this.state.summary.refundable_user}</td>
							</tr>
							<tr>
								<td className='text-start'>Non-Refundable</td>
								<td>{this.state.summary.non_refundable_count}</td>
								<td>{this.state.summary.non_refundable_amount}</td>
								<td>{this.state.summary.non_refundable_user}</td>
							</tr>
						</tbody>
					</table>
				}
				<Table
					title='Cancellation Requested Items'
					key={this.state.muiTableKey}
					options={{
						toolbar: true,
            sorting: false, filtering: false,
						debounceInterval: 1000,
					}}
					actions={CONFIG.TABLE_ACTIONS(this)}
          columns={CONFIG.TABLE_COLUMNS(this)}
					tableRef={this._tableRef}
					data={(query) => fetchData(query, this)}
				/>
			</div>
		);
	}
}

export default withSnackbar(CancellationRequestedItems)
