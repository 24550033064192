import React from "react";
import pureIcons from "../../shared/pure_icons";
import RangePicker from "react-range-picker";
import {handleRequestRefund} from "./api"
const TABLE_COLUMNS = (component, onDateChanges, placeholder, footer) => [
  { title: 'Orders', field: 'order_number', filtering: false, sorting: false },
  { title: 'Product Name', field: 'product_name', filtering: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 300 }, sorting: false },
  { title: 'Quantity', field: 'quantity', filtering: false, sorting: false },
  { title: 'Price', field: 'product_price', filtering: false, sorting: false },
  { title: 'Refund Amount', field: 'refund_kidus_amount', filtering: false, sorting: false },
  { title: 'Canceled By', field: 'canceled_by', filtering: false, sorting: false },
  { title: 'Cancel Reason', field: 'admin_cancel_reason', filtering: false, sorting: false },
  { title: 'Date', field: 'cancel_date', filterComponent: () => <RangePicker onDateSelected={onDateChanges} rangeTillEndOfDay={true} placeholderText='Select date' dateFormat='DDDD-MMMM-YYYY' placeholder={placeholder} footer={footer} /> },
];

const TABLE_ACTIONS = (component) => [
  {
    icon: () => <pureIcons.FiRefreshCw />,
    onClick: () => {component.reloadData();},
    isFreeAction: true,
    tooltip: "Refresh",
  },
  rowData => ({
    icon: () => <pureIcons.FaDollarSign />,
    tooltip: <span>{rowData.refundable ? 'Request Refund' : 'Non Refundable'}</span>,
    disabled: !rowData.refundable || !rowData.refund_requester,
    onClick: (event, rowData) => { window.confirm(`Are you sure you want to Request a refund for order ${rowData.order_number}?`) && handleRequestRefund(rowData, component) },
    position: 'row'
  }),
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
