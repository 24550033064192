import React from 'react';
import Table from '../../shared/tables/material_table';
import $ from "jquery";
import { withSnackbar } from 'notistack';
import Backdrop from '../../shared/backdrop'
import pureIcons from "../../shared/pure_icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class InTransit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			global_control: {},
			file_path: null,
			locations: [],
			carriers: []
		};
		this._tableRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

	async componentDidMount() {
		try {
			const response = await fetch('/order_item_stages/in_transit.json?componentDidMount=true');
			const data = await response.json();
			window.breadcrumb.addBreadcrumb(data.breadcrumb);
			this.setState({ global_control: data.global_control, locations: data.locations, carriers: data.carriers });
		} catch (error) {
			console.error('Error: ', error);
		}
	}

	handleUpdate(tracking, oldValue, newValue, columnDef) {
		let self = this;
		let url, data;
		if (oldValue === newValue || newValue === '' || newValue === null) return;
		url = `/order_item_stages/update_stage_tracking`
		data = { tracking: tracking, new_tracking: newValue, stage: 'shipped_to_final_destination' }
		this.setState({ working: true });
		$.ajax({
			url: url,
			type: 'PATCH',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}

	handleUpdatePhases(data) {
		let self = this;
		const url = `/order_item_stages/add_stage_phase`
		this.setState({ working: true });
		const payload = { tracking_numbers: data.map(p=> p.tracking) }
		$.ajax({
			url: url,
			type: 'POST',
			data: payload,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}


	render() {
		const columns = [
			{ title: 'Tracking', field: 'tracking', sorting: false, cellStyle: { width: '10%' }, filtering: false, editable: ( _ ,rowData ) => rowData.edit_tracking },
			{ title: 'Date Shipped', field: 'created_at', sorting: false, editable: 'never', filtering: false },
			{
				title: 'Items', field: 'products_count', sorting: false, filtering: false, editable: 'never', render: rowData => {
					return rowData.partial_processed ? <div style={{ color: "#FF0000", 'fontWeight': 'bold' }}>{rowData.products_count}</div> : <div>{rowData.products_count}</div>
				}
			},
			{
				title: 'Carrier', field: 'group_id', sorting: false, editable: 'never', filterComponent: ({ columnDef, onFilterChanged }) => <Select
					name={columnDef.tableData.id}
					value={columnDef.tableData.location}
					onChange={(e) => {
						onFilterChanged(columnDef.tableData.id, e.target.value);
					}}
				>
					<MenuItem value="">
						<em>All</em>
					</MenuItem>
					{this.state.carriers.map(v =>
						<MenuItem key={v} value={v}>{v}</MenuItem>
					)}
				</Select>
			},
			{ title: 'Method', field: 'shipping_method', sorting: false, editable: 'never', filtering: false },
			{ title: 'Shipment info', field: 'shipment_info', sorting: false, editable: 'never', filtering: false },
			{ title: 'Carrier Data', field: 'carrier_data', sorting: false, editable: 'never', filtering: false },
			{
				title: 'Location', field: 'location', sorting: false, editable: 'never', filterComponent: ({ columnDef, onFilterChanged }) => <Select
					name={columnDef.tableData.id}
					value={columnDef.tableData.location}
					onChange={(e) => {
						onFilterChanged(columnDef.tableData.id, e.target.value);
					}}
				>
					<MenuItem value="">
						<em>All</em>
					</MenuItem>
					{this.state.locations.map(v =>
						<MenuItem key={v} value={v}>{v}</MenuItem>
					)}
				</Select>
			},
			{ title: 'Current Status', field: 'current_location', sorting: false, editable: 'never', cellStyle: { width: '15%' }, filtering: false },
			{ title: 'Last Update', field: 'current_location_date', editable: 'never', sorting: false, filtering: false },
			{
				title: 'ETA', field: 'eta', editable: 'never', sorting: false, filtering: false, render: rowData => {
					return rowData.flag ? <div style={{ color: "#FF0000" }}>{rowData.eta}</div> : <div>{rowData.eta}</div>
				}
			},
		]

		return (
			<div className='bg-white rounded'>
				<Backdrop open={this.state.working} />
				<Table
					title={'In Transit to Sorting Facility'}
					key={this.state.muiTableKey}
					options={{ debounceInterval: 1000, selection: true}}
					columns={columns}
					tableRef={this._tableRef}
					cellEditable={{
						onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
							return new Promise((resolve, reject) => {
								setTimeout(() => {
									this.handleUpdate(rowData.tracking, oldValue, newValue, columnDef);
									resolve();
								}, 500)
							});
						}
					}}
					actions={[
						{
							icon: () => <pureIcons.GiBoxUnpacking />,
							tooltip: 'open this box',
							onClick: (event, rowData) => window.open(rowData.show_page, '_self'),
							position: 'row'
						},
						{
							icon: () => <pureIcons.FiRefreshCw />,
							hidden: !this.state.global_control?.print_invoice,
							tooltip: 'update phases',
							onClick: (event, rowData) => this.handleUpdatePhases(rowData.tracking, rowData.update_phases),
							position: 'row'
						},
						{
							icon: () => <pureIcons.FiRefreshCw />,
							onClick: (event, rowData) => { this.reloadData() },
							isFreeAction: true,
							tooltip: 'Refresh'
						},
						rowData => ({
							icon: () => <pureIcons.GoPackage />,
							tooltip: 'Add stage phase',
							disabled: !this.state.global_control?.update_stage_phases,
							onClick: () => window.confirm(`Are you sure you want to move selected shipments to the next stage?`) && this.handleUpdatePhases(rowData)
						})
					]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
							}
							let url = `/order_item_stages/in_transit.json?`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.orders,
										page: result.page - 1,
										totalCount: result.total,
									});
								})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(InTransit)
