import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from "../../shared/pure_icons";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ShopperItemsInSortingFacility from './_shopper_items_in_sorting_facility'

class SortingFacility extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			file_path: null
		};
		this._tableRef = React.createRef();
		this._ShopperItemsInSortingFacilityRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}


	render() {
		const columns = [
			{ title: 'Shopper Name', field: 'shopper_name', sorting: false, filtering: false },
			{ title: 'Shopper Phone', field: 'shopper_phone', sorting: false, filtering: false },
			{
				title: 'Delivery Method', field: 'home_delivery', sorting: false, filterComponent: ({ columnDef, onFilterChanged }) => <Select
					name={columnDef.tableData.id}
					value={columnDef.tableData.home_delivery}
					onChange={(e) => {
						onFilterChanged(columnDef.tableData.id, e.target.value);
					}}
				>
					<MenuItem value="">
						<em>All</em>
					</MenuItem>
					{['Home Delivery', 'Pickup'].map(v =>
						<MenuItem key={v} value={v}>{v}</MenuItem>
					)}
				</Select>
			},
			{
				title: 'Status', field: 'completed', sorting: false, filterComponent: ({ columnDef, onFilterChanged }) => <Select
					name={columnDef.tableData.id}
					value={columnDef.tableData.home_delivery}
					onChange={(e) => {
						onFilterChanged(columnDef.tableData.id, e.target.value);
					}}
				>
					<MenuItem value="">
						<em>All</em>
					</MenuItem>
					{['Completed'].map(v =>
						<MenuItem key={v} value={v}>{v}</MenuItem>
					)}
				</Select>
			},
		]
		return (
			<div className='bg-white rounded'>
				<ShopperItemsInSortingFacility ref={this._ShopperItemsInSortingFacilityRef} reloadData={this.reloadData} />
				<Table
					title={'Received in Sorting Facility'}
					key={this.state.muiTableKey}
					options={{ debounceInterval: 500, actionsColumnIndex: -1 }}
					columns={columns}
					tableRef={this._tableRef}
					actions={[
						{
							icon: () => <pureIcons.GiBoxUnpacking />,
							tooltip: <span>Process</span>,
							onClick: (event, rowData) => this._ShopperItemsInSortingFacilityRef.current.handleOpen(rowData.user_id),
							position: 'row'
						},
						{
							icon: () => <pureIcons.FiRefreshCw />,
							onClick: () => { this.reloadData() },
							isFreeAction: true,
							tooltip: <span>Refresh</span>
						},
					]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
							}
							let url = `/orders/sorting_facility.json?`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.shoppers,
										page: result.page - 1,
										totalCount: result.total,
									});
									window.breadcrumb.addBreadcrumb(result.breadcrumb)
								})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(SortingFacility)
