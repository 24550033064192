import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardMedia, CircularProgress, IconButton, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Modal from '../shared/modal/material_dialog';
import Select from '../shared/autocomplete';
import { useSnackbar } from 'notistack';
import Backdrop from "../shared/backdrop";
import NumericInput from 'react-numeric-input';

export default function CancelForm({ product, callBackDeleteItem, qty, stage, order_item_stage_id }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [cancelReason, setCancelReason] = React.useState('');
  const [dQty, setDqty] = React.useState(qty);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectCallBack = (data) => {
    setCancelReason(data.value);
    validate(data.value);
  };

  const validate = (fieldValues = cancelReason) => {
    let temp = { ...errors };
    temp.cancelReason = fieldValues ? '' : 'Required';
    setErrors({ ...temp });
    return Object.values(temp).every(x => x === '');
  };

  const handleClose = () => {
    if (working) return;
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setDqty(qty);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setWorking(true);
    const data = {
      cancel_reason: cancelReason,
      qty: dQty,
      subtotal: product.price * dQty,
      stage_id: order_item_stage_id,
    };

    try {
      const response = await fetch(`/order_items/${product.order_item_id}/request_cancellation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Something went wrong');
      }

      enqueueSnackbar('Successfully requested cancellation!', { variant: 'success' });
      callBackDeleteItem(qty - dQty);
      handleClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setWorking(false);
    }
  };

  const actions = working ? (
    <CircularProgress className={classes.button} />
  ) : (
    <Button
      variant='contained'
      style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
      type='submit'
      className={classes.button}
      disabled={working}
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );

  return (
    <>
      <IconButton aria-label='delete' onClick={handleOpen}>
        <DeleteForeverIcon style={{ color: '#f50057', fontSize: 30 }} />
      </IconButton>
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={`Cancel Item in stage: ${stage}`}
        handleClose={handleClose}
        maxHeight='60%'
        maxWidth='md'
        actions={actions}
      >
        <Card className={classes.root}>
          <CardMedia className={classes.cover}>
            <img src={product.image} alt={product.name} style={{ height: '250px', width: '200px', objectFit: 'contain' }} />
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component='h5' variant='h5'>{product.name}</Typography>
              <Typography variant='subtitle1' color='textSecondary'>Price: {product.price}</Typography>
              <Typography variant='subtitle1' color='textSecondary' style={{ paddingBottom: 5 }}>
                Available Qty: {qty}
              </Typography>
              <Typography variant='subtitle1' color='textSecondary' style={{ paddingBottom: 5 }}>
                <NumericInput min={1} max={qty} value={dQty} mobile size={4} onChange={(e, value) => setDqty(value)} name='qty' />
              </Typography>
              <Select
                name='cancel_reason1'
                label='Cancellation Reason'
                url='/codes/dropdown_options.json?code_category_name=Cancellation_reasons'
                style={{ width: 600 }}
                error={errors.cancelReason}
                callBack={handleSelectCallBack}
              />
            </CardContent>
          </div>
        </Card>
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 10,
    margin: 5
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    objectFit: 'contain'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));
