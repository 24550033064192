import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import {Button, Card, CardContent, CardMedia, CircularProgress, Typography} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import Select from '../../shared/autocomplete'
import {withSnackbar} from 'notistack';
import Backdrop from "../../shared/backdrop";
import {makeStyles} from '@material-ui/core/styles';
import {handleReject, handleSubmit} from './api'
import ShowItem from '../../order_items/show';
import pureIcons from '../../shared/pure_icons';
const CancelForm = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [working, setWorking] = useState(false);
	const [errors, setErrors] = useState({});
	const [checkBoxValue, setCheckBoxValue] = useState('');
	const [cancelReason, setCancelReason] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [qty, setQty] = useState('');
	const [stageId, setStageId] = useState('');
	const [product, setProduct] = useState('');
	const classes = useStyles();
	const showItemRef = useRef(null);

	useImperativeHandle(ref, () => ({
    handleOpen(stage_id, qty, product) {
      setCancelReason(product.currentCancelReason)
			setRejectReason('')
			setQty(qty)
			setStageId(stage_id)
			setProduct(product)
			setOpen(true);
			setCheckBoxValue('');
			setErrors({});
    }
  }));
	const handleSelectCallBack = (data) => {
		setCancelReason(data)
		validate('cancelReason', data);
	};

	const validate = (fieldName = null, fieldValue = null) => {
    let temp = { ...errors };

    // If validating a single field
    if (fieldName) {
      temp[fieldName] = fieldValue ? "" : "Required";
    }
    // If validating the entire form (before submit)
    else {
        temp.cancelReason = cancelReason ? "" : "Required";
    }
		if (!checkBoxValue && !fieldValue) {
			temp.checkBoxValue = "Select refund option";
		} else {temp.checkBoxValue = ""}
    setErrors(temp);
    return Object.values(temp).every(x => x === "");
	};

	const body = <Card className={classes.root}>
		<CardMedia className={classes.cover}>
			<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}}/>
		</CardMedia>
		<div className={classes.details}>
			<CardContent className={classes.content}>
				<Typography component='h5' variant='h5'>
					{product.name}
				</Typography>
				<Typography variant='subtitle1' color='textSecondary' pb={5}>
					QTY: {qty}
				</Typography>
				<Typography variant='subtitle1' color='textSecondary' pb={5}>
					Price: {product.price}
				</Typography>
				<Select
					name='cancel_reason1'
					label='Cancellation Reason'
					url='/codes/dropdown_options.json?code_category_name=Cancellation_reasons'
					style={{width: 700}}
					error={errors.cancelReason}
					callBack={handleSelectCallBack}
					selected={cancelReason}
				/>
			</CardContent>
		</div>
	</Card>

	const handleClose = () => {
		if (working) return;
		setOpen(false);
	};

	const actions = (
		working ?
			<CircularProgress/> :
			<>
				<Button
					variant='contained'
					color="secondary"
					type='submit'
					disabled={working || !rejectReason}
					onClick={(e) => handleReject(e, props, setWorking, handleClose, stageId, rejectReason, product.order_item_id)}
				>
					Reject
				</Button>
				<Select
					name='cancel_reject_reason'
					label='Reject Reason'
					className={classes.select}
					url='/codes/dropdown_options.json?code_category_name=cancel_reject_reason'
					callBack={(data) => setRejectReason(data.value)}
				/>
				<div style={{flex: '1 0 0'}} />
				<div className={`row ${errors.checkBoxValue ? "alert alert-danger" : ""}`}>
					<div className="row">
						<div className="form-check form-switch">
							<input
									className="form-check-input"
									type="checkbox"
									id="fullRefund"
									name="fullRefund"
									checked={checkBoxValue === 'fullRefund'}
									onChange={() => {setCheckBoxValue('fullRefund'); validate(null, true)}}
							/>
							<label className="form-check-label" htmlFor="fullRefund">Full refund</label>
						</div>
					</div>

					<div className="row">
						<div className="form-check form-switch">
							<input
									className="form-check-input"
									type="checkbox"
									id="itemPriceOnly"
									name="itemPriceOnly"
									checked={checkBoxValue === 'itemPriceOnly'}
									onChange={() => {setCheckBoxValue('itemPriceOnly'); validate(null, true)}}
							/>
							<label className="form-check-label" htmlFor="itemPriceOnly">Item price only</label>
						</div>
					</div>

					<div className="row">
						<div className="form-check form-switch">
							<input
									className="form-check-input"
									type="checkbox"
									id="shippingAndCustomsOnly"
									name="shippingAndCustomsOnly"
									checked={checkBoxValue === 'shippingAndCustomsOnly'}
									onChange={() => {setCheckBoxValue("shippingAndCustomsOnly"); validate(null, true)}}
							/>
							<label className="form-check-label" htmlFor="shippingAndCustomsOnly">All cost except item price</label>
						</div>
					</div>
				</div>

				<Button
					variant='contained'
					style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
					type='submit'
					disabled={working || rejectReason}
					onClick={e => handleSubmit(e, validate,
						setWorking, {cancelReason, qty, itemPriceOnly, product, stageId, checkBoxValue},
						props, handleClose
					)}
				>
					Approve
				</Button>
			</>
	);

	return (
		<>
			<Backdrop open={working}/>
			<ShowItem ref={showItemRef} />
			<Modal
				disableBackdropClick
				isOpen={open}
				title={
					<>
						Cancel Item
						<pureIcons.ImInfo
							style={{ cursor: 'pointer', marginLeft: 10 }}
							onClick={() => showItemRef.current?.handleOpen(product?.order_item_id)}
						/>
					</>
				}
				handleClose={handleClose}
				maxHeight='60%'
				maxWidth='lg'
				actions={actions}>
				{body}
			</Modal>
		</>
	);

});

export default withSnackbar(CancelForm);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
	select: {
    marginBottom: 5,
		paddingBottom: 5,
		width: 500
	},
}));
