import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import { Backdrop } from '@material-ui/core';
import CONFIG from "./tableConfig";
import {fetchData} from './api'
class CanceledItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      working: false,
      date: null,
      date2: null,
      muiTableKey: 0,
    };
    this._tableRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const onDateChanges = (date, date2) => {
      if (!date2) return;
      this.setState({ date: date, date2: date2 })
      this._tableRef.current.onQueryChange();
    };

    const clear = () => {
      if (this.state.date && this.state.date2) {
        this.setState({ date: null, date2: null })
        this._tableRef.current.onQueryChange();
      }
    };
    const placeholder = ({ startDate, endDate }) => {
      if (!this.state.date) {
        return <button type="button" className="btn btn-link">Select date range</button>
      }
      return (
        <div>
          <div>From - {this.state.date.toLocaleDateString("es-GT")}</div>
          {this.state.date2 && (
            <div>To - {this.state.date2.toLocaleDateString("es-GT")}</div>
          )}
        </div>
      );
    };

    const footer = ({ startDate, endDate, today, close }) => {
      return <div className='row pt-2'><button type="button" className="btn btn btn-light btn-sm" onClick={clear}>Clear</button></div>
    }
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title={'Canceled Items'}
          key={this.state.muiTableKey}
          options={{ debounceInterval: 1000}}
          columns={CONFIG.TABLE_COLUMNS(this, onDateChanges, placeholder,footer)}
          tableRef={this._tableRef}
          localization={{ toolbar: { searchPlaceholder: 'Search By Order Number' } }}
          actions={CONFIG.TABLE_ACTIONS(this)}
          data={(query) => fetchData(query, this)}
        />
      </div>
    );
  }
}
export default withSnackbar(CanceledItems)
