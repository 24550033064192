import React from "react";
import pureIcons from "../../shared/pure_icons";

const TABLE_COLUMNS = (component) => [
  { title: 'Order No.', field: 'order_number' },
  { title: 'Phone No.', field: 'phone_number' },
  { title: 'QTY', field: 'qty' },
  { title: 'Price', field: 'product_price' },
  { title: 'Refund Kidus Amount', field: 'refund_kidus_amount' },
  { title: 'Refund Status', field: 'status', lookup: { refund_rejected: 'Rejected', refunded: 'Refunded' }  },
  { title: 'Cancel/Reject Reason', field: 'admin_cancel_reason' },
  { title: 'Date Requested', field: 'requested_at' },
  { title: 'Processed by', field: 'processed_by' }
];

const TABLE_ACTIONS = (component) => [
  {
    icon: () => <pureIcons.FiRefreshCw />,
    onClick: () => {component.reloadData();},
    isFreeAction: true,
    tooltip: "Refresh",
  },
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
