

import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import { fetchData } from "./api";
import CONFIG from "./tableConfig";

class RefundRejectedItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
    };
    this._tableRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div className='bg-white rounded'>
        <Table
          title='Refund Final Status'
          key={this.state.muiTableKey}
          options={{
            toolbar: true,
            sorting: false, filtering: false,
            debounceInterval: 1000,
          }}
          actions={CONFIG.TABLE_ACTIONS(this)}
          columns={CONFIG.TABLE_COLUMNS(this)}
          tableRef={this._tableRef}
          data={(query) => fetchData(query, this)}
        />
      </div>
    );
  }
}

export default withSnackbar(RefundRejectedItems)
