const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchData = async (query, component) => {
  let url = `/order_item_stages/cancellation_requested_items.json?`;
  url += "&per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  var filters;
  if (query.filters.length > 0) {
    filters = query.filters.map((col) => ({
      field: col.column.field,
      value: col.value,
    }));
    url += "&filters=" + JSON.stringify(filters);
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) {
    url += "&search=" + query.search;
  }
  const response = await fetch(url);
  const result = await response.json();
  window.breadcrumb.addBreadcrumb(result.breadcrumb);
  component.setState({summary: result.summary})
  return {
    data: result.records,
    page: result.page - 1,
    totalCount: result.total,
  };
};

export const handleReject = async (event, props, setWorking, handleClose, stageId, rejectReason, order_item_id) => {
  event.preventDefault();
  setWorking(true);

  const data = { order_item_stage_id: stageId, cancel_reject_reason: rejectReason};

  try {
    const response = await fetch(`/order_items/${order_item_id}/cancellation_rejected`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }

    setWorking(false);
    props.enqueueSnackbar('Successfully rejected!', { variant: 'success' });
    props.callBackDeleteItem();
    handleClose();
  } catch (error) {
    setWorking(false);
    props.enqueueSnackbar(error.message, { variant: 'error' });
  }
};

export const handleSubmit = async (event, validate, setWorking, formData, props, handleClose) => {
  event.preventDefault();
  if (!validate()) return;
  setWorking(true);
  const data = {
    cancel_reason: formData.cancelReason.id || formData.cancelReason.value,
    qty: formData.qty,
    subtotal: formData.product?.price ? formData.product.price * formData.qty : 0,
    order_item_stage_id: formData.stageId,
    refund_option: formData.checkBoxValue
  };

  try {
    const response = await fetch(`/order_items/${formData.product.order_item_id}/cancel`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }

    setWorking(false);
    props.enqueueSnackbar('Successfully canceled!', { variant: 'success' });
    props.callBackDeleteItem();
    handleClose();
  } catch (error) {
    setWorking(false);
    props.enqueueSnackbar(error.message, { variant: 'error' });
  }
};
