import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import { Button, Card, CardContent, CardMedia, CircularProgress, Typography } from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog';
import Select from '../../shared/autocomplete';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import { makeStyles } from '@material-ui/core/styles';
import { handleSubmit } from './api';
import ShowItem from '../../order_items/show';
import pureIcons from '../../shared/pure_icons';
import NumericInput from 'react-numeric-input';

const RefundFrom = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [working, setWorking] = useState(false);
	const [rejectReason, setRejectReason] = useState('');
	const [refundKidusAmount, setRefundKidusAmount] = useState('');
	const [qty, setQty] = useState('');
	const [newRefundKidusAmount, setNewRefundKidusAmount] = useState('');
	const [stageId, setStageId] = useState('');
	const [product, setProduct] = useState(null);
	const showItemRef = useRef(null);

	const classes = useStyles();

	useImperativeHandle(ref, () => ({
		handleOpen(stage_id, qty, refundKidusAmount, product) {
			setQty(qty);
			setStageId(stage_id);
			setRefundKidusAmount(refundKidusAmount);
			setProduct(product);
			setOpen(true);
			setRejectReason('')
			setNewRefundKidusAmount(refundKidusAmount)
		}
	}));

	const handleClose = () => {
		if (working) return;
		setOpen(false);
	};

	const actions = working ? (
		<CircularProgress />
	) : (
		<>
			<Button
				variant='contained'
				color="secondary"
				type='submit'
				disabled={working || !rejectReason}
				onClick={e => handleSubmit(
					e, product.cancel_reason_key,	stageId, 'rejected', qty, setWorking, props, product, handleClose, newRefundKidusAmount, rejectReason
				)}
			>
				Reject
			</Button>
			<Select
				name='refund_reject_reason'
				label='Reject Reason'
				className={classes.select}
				url='/codes/dropdown_options.json?code_category_name=refund_reject_reason'
				callBack={(data) => setRejectReason(data.value)}
			/>
			<div style={{ flex: '1 0 0' }} />
			<Button
				variant='contained'
				style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
				type='submit'
				disabled={working || rejectReason}
				onClick={e => handleSubmit(
					e, product.cancel_reason_key, stageId, 'approved', qty, setWorking, props, product, handleClose, newRefundKidusAmount
				)}
			>
				Approve
			</Button>
		</>
	);

	return (
		<>
			<Backdrop open={working} />
			<ShowItem ref={showItemRef} />
			<Modal
				disableBackdropClick
				isOpen={open}
				title={
					<>
						Review Refund Request
						<pureIcons.ImInfo
							style={{ cursor: 'pointer', marginLeft: 10 }}
							onClick={() => showItemRef.current?.handleOpen(product?.order_item_id)}
						/>
					</>
				}
				handleClose={handleClose}
				maxHeight='60%'
				maxWidth='md'
				actions={actions}
			>
				{product && (
					<Card className={classes.root}>
						<CardMedia className={classes.cover}>
							<img src={product.image} alt={product.name} style={{ height: '250px', width: '200px', objectFit: 'contain' }} />
						</CardMedia>
						<div className={classes.details}>
							<CardContent className={classes.content}>
								<Typography component='h5' variant='h5'>{product.name}</Typography>
								<Typography variant='subtitle1' color='textSecondary'>QTY: <b>{qty}</b></Typography>
								<Typography variant='subtitle1' color='textSecondary'>Price: <b>{product.price}</b></Typography>
								<Typography variant='subtitle1' color='textSecondary'>Refund Kidus Amount:
									<NumericInput min={0.01} max={refundKidusAmount} step={0.1}  value={newRefundKidusAmount}  size={10} onChange={(e, value) => setNewRefundKidusAmount(value > refundKidusAmount ? refundKidusAmount : value)} name='refundKidusAmount'/>
								</Typography>
								<Typography variant='subtitle1' color='textSecondary'>Cancel reason: <b>{product.currentCancelReason}</b></Typography>
							</CardContent>
						</div>
					</Card>
				)}
			</Modal>
		</>
	);
});

export default withSnackbar(RefundFrom);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
	select: {
		marginBottom: 5,
		paddingBottom: 5,
		width: 500
	},
}));
