import React from "react";
import pureIcons from "../../shared/pure_icons";
const TABLE_COLUMNS = (component) => [
  { title: 'Order No.', field: 'order_number' },
  { title: 'QTY', field: 'available_qty' },
  { title: 'Price', field: 'product_price' },
  { title: 'Refund Kidus Amount', field: 'refund_kidus_amount' },
  { title: 'Payment Type', field: 'payment_type'},
  { title: 'Requested by', field: 'requested_by' },
  { title: 'Cancel Reason', field: 'admin_cancel_reason' },
  { title: 'Date Requested', field: 'requested_at' }
];

const TABLE_ACTIONS = (component) => [
  {
    icon: () => <pureIcons.FiRefreshCw />,
    onClick: () => {component.reloadData();},
    isFreeAction: true,
    tooltip: "Refresh",
  },
  rowData => ({
    icon: () => <pureIcons.MdPreview />,
    tooltip: "Approve",
    hidden: !rowData.refund_approver,
    onClick: (event, rowData) =>
      component._RefundItemRef.current.handleOpen(
        rowData.id,
        rowData.qty,
        rowData.refund_kidus_amount,
        {image: rowData.image, order_item_id: rowData.order_item_id,
          name: rowData.product_name, price: rowData.product_price,
          currentCancelReason: rowData.admin_cancel_reason,
          currentCancelReason2: rowData.user_cancel_reason,
          admin_cancel_reason_key: rowData.admin_cancel_reason_key,
          user_cancel_reason_key: rowData.user_cancel_reason_key
        }
      )
    ,
    position: "row",
  }),
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
