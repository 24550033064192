import React from 'react';
import {Breadcrumbs, Link, Typography} from '@material-ui/core';

export default class Breadcrumb extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb_data: this.props.breadcrumb_data || []
		};
		window.breadcrumb = this;
	}

	addBreadcrumb = (breadcrumb_data) => {
		if (breadcrumb_data)
			this.setState({ breadcrumb_data: breadcrumb_data });
		else
			this.setState({ breadcrumb_data: [] });
	}

	render () {
		const buildList = (
			this.state.breadcrumb_data.map(l =>
				l.url === '#' ?
				<Link color="inherit" key={l.name}>{l.name}</Link>
				: l.url === '@' ?
				<Typography color="textPrimary" key={l.name}>{l.name}</Typography>
				:
				<Link color="inherit" href={l.url} key={l.name}>{l.name}</Link>
			)
		)
		return (
			<Breadcrumbs aria-label='breadcrumb' className='st-code-breadcrumb'>
				{this.state.breadcrumb_data.length > 0 &&
					<Link color="inherit" href='/'>Home</Link>
				}
				{buildList}
			</Breadcrumbs>
		);
	}
}
