import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import $ from "jquery";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import WaybillsReport from "./_report";
import WaybillsShow from "./_show_way_bills";

class Waybills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
      enableShow: null,
      enableEdit: null,
    };
    this._tableRef = React.createRef();
    this._inputFileRef = React.createRef();
    this._WaybillsReportRef = React.createRef();
    this._WaybillsShowRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  handleUpdate = (data) => {
    let self = this;
    const payload = {
      total_cost: data.billed_cost,
      total_customs: data.billed_customs,
      total_weight: data.billed_weight,
    };
    $.ajax({
      url: `/waybills/${data.id}`,
      type: "PATCH",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  handleBulkUpdate = (e) => {
    let self = this;
    this.setState({ working: true });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    $.ajax({
      url: `/waybills/bulk_update`,
      type: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  render() {
    const columns = [
      { title: "Waybill No.", field: "waybill_number", editable: "never" },
      { title: "Carrier", field: "carrier", editable: "never" },
      { title: "Pure Weight", field: "pure_weight", editable: "never" },
      { title: "Pure Shipping", field: "pure_shipping", editable: "never" },
      { title: "Pure Customs", field: "pure_customs", editable: "never" },
      { title: "Billed Weight", field: "billed_weight" },
      { title: "Billed Shipping", field: "billed_cost" },
      { title: "Billed Customs", field: "billed_customs" },
      { title: "Date Shipped", field: "created_at", editable: "never" },
    ];

    const onButtonClick = () => {
      // `current` points to the mounted file input element
      this._inputFileRef.current.click();
    };
    return (
      <div className="bg-white rounded">
        <WaybillsReport ref={this._WaybillsReportRef} />
        <WaybillsShow ref={this._WaybillsShowRef} />
        <input
          type="file"
          id="file"
          name="file"
          accept=".csv"
          ref={this._inputFileRef}
          style={{ display: "none" }}
          onChange={this.handleBulkUpdate}
        />
        <Backdrop open={this.state.working} />
        <Table
          title="Waybills"
          key={this.state.muiTableKey}
          options={{
            toolbar: true,
            sorting: false,
            filtering: false,
            debounceInterval: 1000
          }}
          columns={columns}
          tableRef={this._tableRef}
          editable={{
            isEditable: (rowData) => rowData.enable_edit, // Only allow edit when enable_edit is true
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (!newData.cost || !newData.customs || !newData.weight) {
                  reject();
                  return;
                }
                resolve();
                if (oldData) {
                  handleUpdate(newData);
                }
              }),
          }}
          actions={[
            {
              icon: () => <pureIcons.FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              icon: () => <pureIcons.MdOutlineSummarize />,
              onClick: () => {
                this._WaybillsReportRef.current.handleOpen();
              },
              isFreeAction: true,
              tooltip: "Download Waybills Report",
            },
            {
              icon: () => <pureIcons.Edit />,
              onClick: () => {
                onButtonClick();
              },
              isFreeAction: true,
              tooltip: "Bulk update",
            },
            // (rowData) => ({
            //   icon: () => <pureIcons.BiShow />,
            //   onClick: () => {
            //     this._WaybillsShowRef.current.handleOpen(
            //       rowData.id,
            //       rowData.waybill_number
            //     );
            //   },
            //   tooltip: "Show WayBill",
            // })
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/waybills.json?`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.waybills,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(Waybills);
