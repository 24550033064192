const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchData = async (query, component) => {
  let url = `/order_item_stages/canceled_items.json?date_start=${component.state.date}&date_end=${component.state.date2}`;
  url += "&per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  var filters;
  if (query.filters.length > 0) {
    filters = query.filters.map((col) => ({
      field: col.column.field,
      value: col.value,
    }));
    url += "&filters=" + JSON.stringify(filters);
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) {
    url += "&search=" + query.search;
  }
  const response = await fetch(url);
  const result = await response.json();
  window.breadcrumb.addBreadcrumb(result.breadcrumb);
  return {
    data: result.records,
    page: result.page - 1,
    totalCount: result.total,
  };
};

export const handleRequestRefund = async (rowData, component) => {
  component.setState({ working: true });

  try {
    const response = await fetch(` /order_items/${rowData.order_item_id}/refund_requested`, {
      method: "POST",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({
        order_item_stage_id: rowData.order_item_stage_id,
        stage_qty: rowData.quantity,
        cancel_reason: rowData.cancel_reason,
        customer_cancel_reason: rowData.customer_cancel_reason,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }
    component.reloadData();
    component.props.enqueueSnackbar("Successfully processed!", {
      variant: "success",
    });
  } catch (error) {
    component.props.enqueueSnackbar(error.message, {
      variant: "error",
    });
  } finally {
    component.setState({ working: false });
  }
};



