import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from "../../shared/pure_icons";
import $ from "jquery";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ReadyForDelivery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			working: false,
			muiTableKey: 0,
			user_list: []
		};
		this._tableRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

	handleMakeAsDelivered = (rowData) => {
		let self = this;
		this.setState({working: true});
		$.ajax({
			url: `/order_item_stages/make_as_delivered`,
			type: 'POST',
			data: {group_id: rowData.group_id},
			dataType: 'json',
			success: function (response) {
				self.reloadData();
				self.setState({working: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
			},
			error: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	render() {
		const columns = [
			{title: 'Orders', field: 'orders', sorting: false, filtering: false},
			{title: 'Customer Phone',	field: 'phone', sorting: false, cellStyle: {width: '5%'}, filtering: false},
			{title: 'Customer Name', field: 'name', sorting: false, filtering: false},
			{title: 'Address', field: 'address', sorting: false, filtering: false},
			{title: 'Items', field: 'items_count', sorting: false, cellStyle: {width: '5%'}, filtering: false},
			{title: 'Date', field: 'create_at', filtering: false},
			{
				title: 'User', field: 'who', sorting: false, filterComponent: ({ columnDef, onFilterChanged }) => <Select
					name={columnDef.tableData.id}
					value={columnDef.tableData.who_id}
					onChange={(e) => {
						onFilterChanged(columnDef.tableData.id, e.target.value);
					}}
				>
					<MenuItem value="">
						<em>All</em>
					</MenuItem>
					{this.state.user_list.map(v =>
						<MenuItem key={v[1]} value={v[1]}>{v[0]}</MenuItem>
					)}
				</Select>
			},
		]

		return (
			<div className='bg-white rounded'>
				<Table
					title={'Ready For Pickup'}
					key={this.state.muiTableKey}
					options={{debounceInterval: 1000, actionsColumnIndex: -1}}
					columns={columns}
					tableRef={this._tableRef}
					actions={
						[
							{
								icon: () => <pureIcons.FiRefreshCw/>,
								onClick: () => {this.reloadData()},
								isFreeAction: true,
								tooltip: 'Refresh'
							},
							{
								icon: () => <pureIcons.ThumbUpIcon/>,
								tooltip: <span>Mark as delivered</span>,
								onClick: (event , rowData) => { window.confirm(`Are you sure you want to mark ${rowData.group_id} as delivered?`) && this.handleMakeAsDelivered(rowData) },
								position: 'row'
							},
						]
					}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({field: col.column.field, value: col.value}))
							}
							let url = '/order_item_stages/ready_for_pickup.json?'
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.records,
										page: result.page - 1,
										totalCount: result.total,
									});
									return result;
								})
								.then(result => {
									if (result.user_list) {
										this.setState({ user_list: result.user_list });
										window.breadcrumb.addBreadcrumb(result.breadcrumb);
									}
								})
								.catch(error => console.error("Error fetching data:", error));
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(ReadyForDelivery)
