import fetch from 'cross-fetch';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Asynchronous({
    url, name, label, variant, callBack, style,
    error, freeSolo = false, helperText, selected, className
}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            return;
        }

        let active = true;
        setLoading(true);
        const controller = new AbortController();

        (async () => {
            try {
                const response = await fetch(url, { signal: controller.signal });
                if (!response.ok) throw new Error('Network response was not ok');

                const responseJSON = await response.json();
                if (active) {
                    setOptions(responseJSON.records.map((e) => ({ value: e.id, label: e.label })));
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error("Fetch error:", error);
                }
            } finally {
                if (active) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            active = false;
            controller.abort();
        };
    }, [open, url]);

    const handleOnChange = (e, newValue) => {
        if (freeSolo && typeof newValue === 'string') {
            callBack({ label: newValue, value: newValue });
        } else {
            callBack(newValue || {});
        }
    };

    return (
        <Autocomplete
            id={name}
            name={name}
            freeSolo={freeSolo}
            style={style}
            value={selected}
            open={open}
            className={className}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.label || ""}
            onChange={handleOnChange}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!error}
                    helperText={error || helperText}
                    variant={variant}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
