const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchData = async (query, component) => {
  let url = `/order_item_stages/refund_approved_items.json?`;
  url += "&per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  var filters;
  if (query.filters.length > 0) {
    filters = query.filters.map((col) => ({
      field: col.column.field,
      value: col.value,
    }));
    url += "&filters=" + JSON.stringify(filters);
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) {
    url += "&search=" + query.search;
  }
  const response = await fetch(url);
  const result = await response.json();
  window.breadcrumb.addBreadcrumb(result.breadcrumb);
  component.setState({total_requests: result.total_requests, total_refund_amount: result.total_refund_amount, number_or_customers: result.number_or_customers})
  return {
    data: result.records,
    page: result.page - 1,
    totalCount: result.total,
  };
};

export const handleCashRefunded = async (rowData, component) => {
  component.setState({working: true});

  const data = {
    order_item_stage_id: rowData.order_item_stage_id,
    stage_qty: rowData.qty,
  };

  try {
    const response = await fetch(`/order_items/${rowData.order_item_id}/cash_refunded`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }

    component.setState({working: false});
    component.props.enqueueSnackbar('Successfully canceled!', { variant: 'success' });
    component.reloadData();
  } catch (error) {
    component.setState({working: false});
    props.enqueueSnackbar(error.message, { variant: 'error' });
  }
};
